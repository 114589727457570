<template>
    <div>
        <div>
            <div class="colorBlock hidden-md-and-down">
                日期(已预约/号数)颜色：
                <div>
                    <i class="el-icon-success colorRed"></i> 停手术
                </div>
                <div>
                    <i class="el-icon-success colorBlue"></i> 临时排班
                </div>
                <div>
                    <i class="el-icon-success colorGreen"></i> 节假日
                </div>
                <el-switch
                    v-model="hideDisabled"
                    active-text="仅可用日期"
                    inactive-text="" @change="changeVisibleDisable">
                </el-switch>
            </div>
            <el-page-header @back="$router.go(-1)" content="手术预约"/>
        </div>

        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-form-item label="手术医生" prop="doctor">
                <radio-doctor v-model="form.doctor"/>
            </el-form-item>
            <el-form-item label="选择手术区" prop="area">
                <checkbox-area v-model="form.area" :doctor="form.doctor"/>
            </el-form-item>
            <el-form-item label="手术日期" prop="date">
                <radio-date v-model="form.date" :doctor="form.doctor" :area="form.area"/>
            </el-form-item>
            <el-form-item label="手术时间" prop="time">
                <radio-time v-model="form.time" :doctor="form.doctor" :date="form.date" :area="form.area"/>
            </el-form-item>
            <el-form-item label="患者姓名" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="初步诊断" prop="diagnosis">
                <el-input v-model="form.diagnosis"></el-input>
            </el-form-item>
            <el-form-item label="手术类型" prop="type">
                <radio-type v-model="form.type" />
            </el-form-item>
            <el-form-item label="备注" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">预约</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import RadioTime from "@/components/base/radioTime";
    export default {
        components: {RadioTime},
        data() {
            return {
                hideDisabled: false,
                operationDates: [],
                operationTimes: [],
                form: {
                    doctor: '',
                    area: [],
                    date: '',
                    time: '',
                    name: '',
                    phone: '',
                    diagnosis: '',
                    type: '',
                    desc: '',
                    source: 'PC端录入',
                },
                rules: {
                    doctor: [
                        {required: true, message: '请选择手术医生', trigger: 'change'}
                    ],
                    area: [
                        {required: true, message: '请选择手术区', trigger: 'blur'}
                    ],
                    date: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                    time: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ],
                    name: [
                        {required: true, message: '请输入患者名称', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入患者联系电话', trigger: 'blur'},
                    ],
                    diagnosis: [
                        {required: true, message: '请输入初步诊断', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请选择手术类型', trigger: 'change'}
                    ],
                }
            };
        },
        methods: {
            visibleDisable(d) {
                if (this.hideDisabled) {
                    if (d) return false
                }
                return true
            },
            changeVisibleDisable(e){
                localStorage.setItem('hideDisabled', e===true ? 'true' : '')
            },
            async submitForm(formName) {
                console.log(this.form)
                const valid = await this.$refs[formName].validate().then(res => res).catch(error => error);
                console.log(valid)
                if (!valid) {
                    return
                }
                const resp = await this.$http.restPostOrPut('/surgery/appoint/', this.form)
                if (resp.code === 200) {
                    this.$message.success("预约成功！")
                    await this.$router.replace("/main/appoint/form/done/")
                } else {
                    this.$message.error(resp.msg)
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/surgery/appoint/${id}`)
                    this.form = resp.data.data
                    // this.form.area_id = this.form.area_id || []
                }
            }
        },
        mounted() {
            this.hideDisabled = Boolean(localStorage.getItem('hideDisabled'))
            this.getData()
        }
    }
</script>

<style scoped>
    .form {
        margin-top: 20px;
    }

    .colorGreen {
        color: #179d00 !important;
    }

    .colorRed {
        color: #e75c09 !important;
    }

    .colorBlue {
        color: #0046c5;
    }

    /*/deep/.is-disabled span.el-radio__label{*/
    /*    color: #179d00 !important;*/
    /*}*/

    .colorBlock {
        float: right;
        display: flex;
        font-size: 14px;
        color: #666666;
    }
    .colorBlock div {
        margin-left: 10px;
    }

</style>
